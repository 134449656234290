{
  "en": {
    "toggle_navigation": "Toggle navigation",
    "mobile_exit_text": "Exit {0}",
    "cancel_modal": {
      "title": "Are you sure?",
      "text": "Go 'Back' or select 'Exit {0}' if you are sure you would like to leave.",
      "text_without_wizard_name": "Go 'Back' or select 'Exit' if you are sure you would like to leave.",
      "button_text": "Exit {0}",
      "cancel_button_text": "Back"
    }
  }
}
