import { i18n } from '@/plugins/i18n.js'
import { Trans } from '@/plugins/Translation.js'

// Routes
import authenticationRoutes from './authentication.js'
import signedInPagesRoutes from './signedInPages.js'
import prescreenRoutes from './prescreen.js'
import surveyRoutes from './survey.js'
import dashboardRoutes from './dashboard.js'
import viewsRoutes from './views.js'

// Main flow
const TheMain = () => import('@/components/views/Main/TheMain.vue')
const Home = () => import('@/components/views/HomeV3/HomeV3.vue')
const Welcome = () => import('@/components/views/Welcome/Welcome.vue')
const TelehealthDetailsPage = () =>
  import('@/components/views/TelehealthDetailsPage/TelehealthDetailsPage.vue')
const Legal = () => import('@/components/views/Main/LegalV3/LegalV3.vue')
const Direct = () => import('@/components/views/Main/Direct/Direct.vue')
const Prescreen = () => import('@/components/views/Main/Prescreen/PrescreenV3.vue')
const PrescreenConsent = () =>
  import('@/components/views/Main/PrescreenConsent/PrescreenConsentV3.vue')
const FAQ = () => import('@/components/views/Main/FAQ/FAQ.vue')
const GuardianConsent = () => import('@/components/views/GuardianConsent/GuardianConsent.vue')
const SignedOutResult = () => import('@/components/views/Results/views/SignedOutResult.vue')
const PharmaTypography = () => import('@/components/views/PharmaTypography/PharmaTypography.vue')
const NotificationPreferences = () =>
  import('@/components/views/NotificationPreferences/NotificationPreferences.vue')
import { getFallbackPath } from '@/utils/routeUtils.js'

export default {
  path: '',
  component: TheMain,
  name: 'TheMain',
  children: [
    ...authenticationRoutes,
    ...signedInPagesRoutes,
    ...prescreenRoutes,
    ...viewsRoutes,
    { ...surveyRoutes },
    { ...dashboardRoutes },
    {
      path: 'home',
      name: 'Home',
      component: Home,
      meta: {
        title: i18n.t('headful_title.home'),
        redirectOnAuthentication: true
      }
    },
    {
      path: 'welcome',
      name: 'Welcome',
      component: Welcome,
      meta: {
        title: i18n.t('headful_title.welcome'),
        redirectOnAuthentication: true
      }
    },
    {
      path: 'telehealth-appointment-info',
      name: 'TelehealthDetailsPage',
      component: TelehealthDetailsPage,
      meta: {
        title: i18n.t('headful_title.telehealth_details')
      }
    },
    {
      path: 'legal/:productId?',
      name: 'Legal',
      component: Legal,
      props: true,
      meta: {
        title: i18n.t('headful_title.terms_and_conditions')
      }
    },
    {
      path: 'direct/:orgId/:productId',
      name: 'Direct',
      component: Direct,
      props: true,
      meta: {
        title: i18n.t('headful_title.direct')
      }
    },
    {
      path: 'direct-prescreen/:accessCode/:orgId/:productId/:eligibilityResponseUuid?',
      name: 'DirectPrescreen',
      component: Prescreen,
      props: true,
      meta: {
        title: i18n.t('headful_title.prescreen'),
        isFooterHidden: true,
        showOrderWizardHeader: true
      }
    },
    {
      path: 'direct-prescreen-consent/:accessCode/:orgId/:productId/:eligibilityResponseUuid?',
      name: 'DirectPrescreenConsent',
      component: PrescreenConsent,
      props: true,
      meta: {
        title: i18n.t('headful_title.prescreen_consent'),
        showOrderWizardHeader: true,
        isFooterHidden: true
      }
    },
    {
      path: 'faq',
      name: 'FAQ',
      component: FAQ,
      meta: {
        title: i18n.t('headful_title.faq')
      }
    },
    {
      path: 'consent/:uuid',
      name: 'GuardianConsent',
      component: GuardianConsent,
      meta: {
        title: i18n.t('headful_title.guardian_consent')
      }
    },
    {
      path: 'external-results/:uuid/',
      name: 'ExternalResults',
      component: SignedOutResult,
      props: true,
      meta: {
        title: i18n.t('headful_title.signed_out_result')
      }
    },
    {
      path: 'notification-preferences',
      name: 'NotificationPreferences',
      component: NotificationPreferences,
      meta: {
        title: i18n.t('headful_title.notification_preferences')
      }
    },
    {
      path: 'typography',
      name: 'PharmaTypography',
      component: PharmaTypography,
      beforeEnter: async (to, from, next) => {
        if (import.meta.env.VUE_APP_ENV === 'dev') {
          return next()
        }
        return next(`${Trans.defaultLanguage}/${getFallbackPath()}`)
      }
    }
  ]
}
