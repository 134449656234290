export const KIT_STATUSES = Object.freeze({
  SHIPPED: 1,
  PRESCREEN_SUBMITTED: 2,
  QUESTIONNAIRE_COMPLETED: 3,
  REGISTERED: 4,
  ORDER_SUBMITTED: 5,
  APPROVED: 6,
  RECEIVED: 7,
  IN_ANALYSIS: 8,
  DATA_READY: 9,
  RESULT_READY: 10
})
