<template>
  <div class="select-test-resources-list">
    <action-card
      v-for="item in items"
      :key="item.id"
      :action="{ title: item.name, description: item.description, icon: item.icon }"
      class="select-test-resources-list__item"
    >
      <template #button>
        <base-btn
          v-for="button in item.buttons"
          :key="button.id"
          class="flex-shrink-0"
          :href="button.href"
          target="_blank"
          :data-is-disclaimer-required="!button.skipDisclaimer"
        >
          <icon-base
            v-if="button.icon"
            :params="{ width: 24, height: 24, viewBox: '0 0 24 24' }"
            fill="none"
          >
            <component :is="getIcon(button)" />
          </icon-base>

          <span>{{ button.text }}</span>
        </base-btn>
      </template>
    </action-card>
  </div>
</template>

<script>
import ActionCard from '@/components/ui-components/ActionCard/ActionCard.vue'
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'

const IxIconArrowUpRightFromSquare = () =>
  import('@/components/icons/IxIconArrowUpRightFromSquare.vue')
const IxIconBoxAlt = () => import('@/components/icons/IxIconBoxAlt.vue')
const IxIconBuilding = () => import('@/components/icons/IxIconBuilding.vue')
const IxIconCircleUp = () => import('@/components/icons/IxIconCircleUp.vue')
const IxIconDocumentAlt = () => import('@/components/icons/IxIconDocumentAlt.vue')
const IxIconDownload = () => import('@/components/icons/IxIconDownloadTray.vue')
const IxIconFinger = () => import('@/components/icons/IxIconFinger.vue')
const IxIconHomeAlt = () => import('@/components/icons/IxIconHomeAlt.vue')
const IxIconPerson = () => import('@/components/icons/IxIconPerson.vue')
const IxIconStethoscopeAlt = () => import('@/components/icons/IxIconStethoscopeAlt.vue')
const IxIconSyringe = () => import('@/components/icons/IxIconSyringe.vue')

export default {
  name: 'SelectTestResourcesList',

  components: { IconBase, ActionCard },

  props: {
    pageContent: {
      type: Object,
      required: true
    }
  },

  computed: {
    items() {
      return this.pageContent?.resources?.items
    }
  },

  methods: {
    getIcon(button) {
      switch (button.icon) {
        case 'box':
          return IxIconBoxAlt

        case 'building':
          return IxIconBuilding

        case 'circle-up':
          return IxIconCircleUp

        case 'document':
          return IxIconDocumentAlt

        case 'download':
          return IxIconDownload

        case 'external':
          return IxIconArrowUpRightFromSquare

        case 'finger':
          return IxIconFinger

        case 'home':
          return IxIconHomeAlt

        case 'person':
          return IxIconPerson

        case 'stethoscope':
          return IxIconStethoscopeAlt

        case 'syringe':
          return IxIconSyringe
      }

      return null
    }
  }
}
</script>

<style scoped lang="scss">
.select-test-resources-list {
  display: flex;
  flex-direction: column;
  gap: $pharma-spacing-sm;

  &__item {
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: stretch;

      .base-btn {
        justify-content: center;
      }
    }

    :deep(.action-card__action-icon-wrap) {
      background: $pharma-color-bg-primary-dim2;
      color: $pharma-color-fg-primary-dim1;
    }
  }
}
</style>
