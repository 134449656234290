<template>
  <path
    d="M5 12H19M19 12L12 5M19 12L12 19"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>

<script>
export default {
  name: 'IxIconArrowRight'
}
</script>
