<i18n src="./locales/lang.json"></i18n>

<template>
  <main id="main" class="select-test-resources">
    <select-test-header
      :progress="100"
      :is-back-visible="true"
      :handle-back-click="handleBackClick"
    />

    <div class="page">
      <div class="select-test-resources__page">
        <div class="container">
          <header class="select-test-resources__header">
            <base-heading tag="h1" as="h4" variant="bold" class="select-test-results__heading">
              {{ title }}
            </base-heading>
            <base-text v-if="description" as="p">{{ description }}</base-text>
          </header>

          <select-test-resources-list :page-content="selectTestPageContent" />
        </div>
      </div>
    </div>

    <outbound-link-disclaimer-popup
      v-show="isOutboundLinkDisclaimerPopupVisible"
      :company-name="companyName"
      selector=".action-card"
      @show="isOutboundLinkDisclaimerPopupVisible = true"
      @close="isOutboundLinkDisclaimerPopupVisible = false"
    />

    <portal-target name="below-page" class="below-page" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import { PortalTarget } from 'portal-vue'
import { CONFIG } from '@/constants/config.js'
import OutboundLinkDisclaimerPopup from '@/components/ui-components/OutboundLinkDisclaimerPopup/OutboundLinkDisclaimerPopup.vue'
import SelectTestHeader from '@/components/views/SelectTestV4/SelectTestHeader.vue'
import SelectTestResourcesList from '@/components/views/SelectTestV4/SelectTestResourcesList.vue'

export default {
  name: 'SelectTestResources',

  components: {
    SelectTestResourcesList,
    SelectTestHeader,
    OutboundLinkDisclaimerPopup,
    PortalTarget
  },

  data() {
    return {
      isOutboundLinkDisclaimerPopupVisible: false
    }
  },

  computed: {
    ...mapGetters({
      getConfig: 'getConfig'
    }),

    headful() {
      return { title: this.$t('headful_title.select_a_test') }
    },

    selectTestPageContent() {
      return this.getConfig(CONFIG.SELECT_TEST_PAGE_V4_CONTENT)
    },

    title() {
      return this.selectTestPageContent.resources?.title
    },

    description() {
      return this.selectTestPageContent.resources?.description
    },

    companyName() {
      return this.getConfig(CONFIG.COMPANY_NAME)
    }
  },

  methods: {
    handleBackClick() {
      this.$router.push({ name: 'SelectTest' }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.select-test-resources {
  &__page {
    padding: 32px 0;

    @include media-breakpoint-up(md) {
      padding: 80px;
    }
  }

  &__header {
    margin-block-end: $pharma-spacing-4xl;
    color: $pharma-color-text-primary;
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }
}
</style>
