import { i18n } from '@/plugins/i18n.js'
import { store } from '@/store/index.js'
import IXLayerAPI from '@/classes/IXLayerAPI.js'

// Dashboard
const DashboardRoot = () => import('@/components/views/Dashboard/Dashboard.vue')
const PreResultConsent = () =>
  import('@/components/views/Dashboard/PreResultConsent/PreResultConsent.vue')

import { fetchLatestProductStatusList } from '@/components/views/PharmaDashboard/partials/orderDetailsService.js'

export default {
  path: 'dashboard',
  component: DashboardRoot,
  children: [
    {
      path: ':productStatusId?',
      name: 'TestDashboard',
      component: () => {
        return store.getters.featureFlags.pharmaPatientPortal
          ? import('@/components/views/PharmaDashboard/PharmaDashboard.vue')
          : import('@/components/views/Dashboard/DashboardV3.vue')
      },
      beforeEnter: async (to, from, next) => {
        if (!store.getters.featureFlags.pharmaPatientPortal) {
          next()
          return
        }

        const loadLatestOrderIfAny = async () => {
          if (!store.getters.productStatusList?.length) {
            await fetchLatestProductStatusList()
          }
          const latestOrder = store.getters.productStatusList[0]
          if (!latestOrder) {
            next()
            return
          }
          next({
            name: 'TestDashboard',
            params: { lang: to.params.lang, productStatusId: latestOrder.id }
          })
        }

        const selectedOrderId = parseInt(to.params.productStatusId, 10)
        if (selectedOrderId) {
          // Check if the order exists, so the productStatusId is valid
          let productStatus = null
          try {
            productStatus = await IXLayerAPI.getProductStatusById(selectedOrderId)
          } catch (error) {
            // Fail silently e.g. because of the order does not exist
          }
          if (!productStatus) {
            // If the order is not valid, switch to the latest ordered test
            loadLatestOrderIfAny()
            return
          }
        } else {
          // If no order is specified in the URL, switch to the latest ordered test
          loadLatestOrderIfAny()
          return
        }
        next()
      },
      meta: { requiresAuth: true }
    },
    {
      // Email templates can still link to /dashboard/test and
      // without this redirect, they are sent to the home page instead of the login screen
      path: 'test',
      redirect: { name: 'TestDashboard' }
    },
    {
      path: ':productId/:productStatusId',
      name: 'PreResultConsent',
      component: PreResultConsent,
      props: true,
      meta: {
        title: i18n.t('headful_title.pre_result_consent'),
        requiresAuth: true
      }
    }
  ]
}
