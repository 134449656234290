<i18n src="./locales/lang.json"></i18n>

<template>
  <popup-v3
    :user-action-btns="userActionBtns"
    :title="$t('outbound_link_disclaimer_popup.title', { companyName })"
    :text1="$t('outbound_link_disclaimer_popup.text', { companyName })"
    show-close-button
    @close="handleClose"
  />
</template>

<script>
import PopupV3 from '@/components/ui-components/Popup/PopupV3.vue'
import { isExternalLink } from '@/utils/domUtils.js'
import { trackOutboundLink } from '@/utils/analytics/events.js'

export default {
  name: 'OutboundLinkDisclaimerPopup',

  components: { PopupV3 },

  props: {
    selector: {
      type: String,
      default: '.page'
    },

    companyName: {
      type: String,
      required: true
    },

    eventParameters: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      targetEl: null,
      userActionBtns: [
        {
          onClick: this.navigate,
          title: this.$t('btn.continue')
        },
        {
          onClick: () => this.handleClose(),
          title: this.$t('btn.cancel'),
          secondary: true
        }
      ]
    }
  },

  created() {
    document.documentElement.addEventListener('click', this.interceptOutboundLinkClicks)
  },

  destroyed() {
    document.documentElement.addEventListener('click', this.interceptOutboundLinkClicks)
  },

  methods: {
    interceptOutboundLinkClicks(event) {
      if (
        event.target.tagName !== 'A' ||
        event.target.closest(this.selector) === null ||
        event.target.dataset.isDisclaimerRequired !== 'true' ||
        !isExternalLink(event.target)
      ) {
        return
      }

      event.preventDefault()

      this.$emit('show')
      this.targetEl = event.target
    },

    handleClose() {
      this.targetEl = null
      this.$emit('close')
    },

    navigate() {
      trackOutboundLink(this.targetEl, this.eventParameters)
      window.open(this.targetEl.href)
      this.handleClose()
    }
  }
}
</script>
