import { store } from '@/store/index.js'
import { Trans } from '@/plugins/Translation.js'
import { getFallbackPath } from '@/utils/routeUtils.js'
import { i18n } from '@/plugins/i18n.js'
import { CONFIG } from '@/constants/config.js'
import SelectTestResources from '@/components/views/SelectTestV4/SelectTestResources.vue'

export default [
  {
    path: 'select-test/resources',
    name: 'SelectTestResources',
    component: SelectTestResources,
    beforeEnter: async (to, from, next) => {
      if (store.getters.featureFlags.enableSelectATest) {
        return next()
      }

      return next(`${Trans.defaultLanguage}/${getFallbackPath()}`)
    },
    meta: {
      title: i18n.t('headful_title.select_a_test')
    }
  },
  {
    path: 'select-test/:previousEligibilityResponseUuid?',
    name: 'SelectTest',
    props: true,
    component: () =>
      store.getters.getConfig(CONFIG.SELECT_TEST_PAGE_VERSION) === 'V4'
        ? import('@/components/views/SelectTestV4/SelectTestV4.vue')
        : import('@/components/views/SelectTest/SelectTest.vue'),
    beforeEnter: async (to, from, next) => {
      if (store.getters.featureFlags.enableSelectATest) {
        return next()
      }

      return next(`${Trans.defaultLanguage}/${getFallbackPath()}`)
    },
    meta: {
      title: i18n.t('headful_title.select_a_test'),
      requireProfileWhenAuthenticated: true
    }
  }
]
