{
  "en": {
    "select_test": {
      "title": "Choose the screening option that fits your needs.",
      "cash_pricing": "Cash pricing",
      "insurance_pricing": "Insurance pricing",
      "product": {
        "not_available_description": "Not available due to provider availability, state regulations, or the selected pricing option.",
        "best_for_your_preferences": "Best for your preferences",
        "cash_pay": "cash pay",
        "due_now": "due now/claim filed later",
        "insurance_not_accepted": "Insurance not accepted",
        "learn_more": "Learn more",
        "select": "Select",
        "not_available": "Not available"
      },
      "hsa_fsa_info": "HSA/FSA accepted",
      "telehealth": {
        "text": "Prefer to speak with a clinician? {link}",
        "book_session": "Book a Telehealth session"
      },
      "error_fetching_products": "There was an error when we tried to get product list."
    }
  }
}
