export const ANALYTICS_CURRENCY = 'USD'
export const ANALYTICS_PAYMENT_TYPE_CC = 'Credit Card'
export const ANALYTICS_USER_ID = 'user_id'

// https://developers.google.com/tag-platform/gtagjs/routing
export const ANALYTICS_GROUP_DEFAULT = 'default'
export const ANALYTICS_GROUP_LIMITED = 'limited'

export const ANALYTICS_DIMENSION_ANSWER = 'answer'
export const ANALYTICS_DIMENSION_APPOINTMENT_ID = 'appointment_id'
export const ANALYTICS_DIMENSION_BIOMARKER = 'biomarker'
export const ANALYTICS_DIMENSION_BUTTON_LABEL = 'btn_label'
export const ANALYTICS_DIMENSION_INDEX = 'index'
export const ANALYTICS_DIMENSION_FINANCING = 'financing'
export const ANALYTICS_DIMENSION_FLOW_NAME = 'flow_name'
export const ANALYTICS_DIMENSION_INTERPRETATION = 'interpretation'
export const ANALYTICS_DIMENSION_METHOD = 'method'
export const ANALYTICS_DIMENSION_PRODUCT_STATUS_ID = 'product_status_id'
export const ANALYTICS_DIMENSION_PRODUCT_REPORTING_NAME = 'product_reporting_name'
export const ANALYTICS_DIMENSION_PROVIDER_LOCATION_ID = 'provider_location_id'
export const ANALYTICS_DIMENSION_QUESTION_ID = 'question_id'
export const ANALYTICS_DIMENSION_SITE_CODE = 'site_code'
export const ANALYTICS_DIMENSION_STATE = 'state'
export const ANALYTICS_DIMENSION_TEST_NAME = 'test_name'

export const ANALYTICS_METRIC_CLICKS = 'clicks'
export const ANALYTICS_METRIC_IMPRESSIONS = 'impressions'

export const ANALYTICS_EVENT_METHOD_EMAIL = 'E-mail'
export const ANALYTICS_EVENT_METHOD_EFAX = 'eFax'

export const ANALYTICS_EVENT_CATEGORY_CTA_CARD = 'CTA Card'
export const ANALYTICS_EVENT_CATEGORY_SIGNED_OUT_HOME = 'Signed out Home'
export const ANALYTICS_EVENT_CATEGORY_SIGNED_OUT_ORDER = 'Signed out order'
export const ANALYTICS_EVENT_CATEGORY_POST_PRESCREEN_PAGE = 'Post Prescreening Page'

export const ANALYTICS_ITEM_LIST_ID_KIT_SELECTION = 'kit_selection'
export const ANALYTICS_ITEM_LIST_NAME_KIT_SELECTION = 'Kit Selection'

export const ANALYTICS_EVENT_VERIFY_EMAIL = 'verify_email'
export const ANALYTICS_EVENT_COMPLETE_PROFILE = 'complete_profile'
export const ANALYTICS_EVENT_QR_CODE_KIT_ACTIVATION = 'qr_code_visit'

// Kit activation and Ordering funnel
export const ANALYTICS_EVENT_BEGIN_KIT_ACTIVATION = 'begin_kit_activation'
export const ANALYTICS_EVENT_ENTER_KIT_ID = 'enter_kit_id'
export const ANALYTICS_EVENT_ANSWER_QUESTIONNAIRE = 'answer_questionnaire'
export const ANALYTICS_EVENT_GIVE_CONSENT = 'give_consent'
export const ANALYTICS_EVENT_ACTIVATE_KIT = 'activate_kit'
export const ANALYTICS_EVENT_VIEW_PROVIDE_SAMPLE_STEP = 'view_provide_sample_step'
export const ANALYTICS_EVENT_VIEW_SHIP_SAMPLE_BACK = 'view_ship_sample_back'
export const ANALYTICS_BTN_LABEL_SCAN_KIT_ID = 'Scan kit ID'

// Questionnaire
export const ANALYTICS_EVENT_ANSWER_QUESTION = 'answer_question'
export const ANALYTICS_EVENT_VIEW_QUESTION = 'view_question'

// Dashboard
export const ANALYTICS_EVENT_EXPAND_ORDER = 'expand_order'
export const ANALYTICS_EVENT_VIEW_RESULT = 'view_result'

// Result
export const ANALYTICS_EVENT_DOWNLOAD_RESULT = 'download_result'
export const ANALYTICS_EVENT_DOWNLOAD_DOCTOR_DISCUSSION_GUIDE = 'download_doctor_discussion_guide'
export const ANALYTICS_EVENT_PRINT_RESULT = 'print_result'
export const ANALYTICS_EVENT_SHARE_RESULT = 'share_result'
export const ANALYTICS_EVENT_EXPAND_OBSERVATION = 'expand_observation'
export const ANALYTICS_EVENT_EXPAND_CLAMP = 'expand_clamp'
export const ANALYTICS_EVENT_FIND_A_SPECIALIST = 'find_a_specialist'

// Appointments
export const ANALYTICS_EVENT_FIND_A_DOCTOR = 'find_a_doctor'
export const ANALYTICS_EVENT_SELECT_APPOINTMENT_DATE = 'select_appointment_date'
export const ANALYTICS_EVENT_SELECT_APPOINTMENT_TIME_SLOT = 'select_appointment_time_slot'
export const ANALYTICS_EVENT_CONFIRM_APPOINTMENT = 'confirm_appointment'
export const ANALYTICS_EVENT_CANCEL_APPOINTMENT = 'cancel_appointment'

// Scheduling
export const ANALYTICS_EVENT_SELECT_PSC_LOCATION_DATE = 'select_psc_location_date'
export const ANALYTICS_EVENT_SELECT_PSC_LOCATION_TIME = 'select_psc_location_time'
export const ANALYTICS_EVENT_SELECT_MOBILE_PHLEBOTOMY_DATE = 'select_mobile_phlebotomy_date'
export const ANALYTICS_EVENT_SELECT_MOBILE_PHLEBOTOMY_TIME = 'select_mobile_phlebotomy_time'

// Eligibility check
export const ANALYTICS_EVENT_ELIGIBILITY_EMAIL_CHANGE = 'eligibility_email_change'

// Select a test
export const ANALYTICS_EVENT_SELECT_FINANCING = 'select_financing'
export const ANALYTICS_EVENT_SELECT_STATE = 'select_state'
export const ANALYTICS_EVENT_SELECT_TEST = 'select_test'

// Video engagement
// https://support.google.com/analytics/answer/9216061?hl=en
export const ANALYTICS_VIDEO_START = 'video_start'
export const ANALYTICS_VIDEO_PROGRESS = 'video_progress'
export const ANALYTICS_VIDEO_COMPLETE = 'video_complete'

// Exceptions
export const ANALYTICS_EXCEPTION_DUPLICATE_KIT_ID = 'duplicate_kit_id'
export const ANALYTICS_EXCEPTION_INELIGIBLE_PROFILE = 'ineligible_profile'
export const ANALYTICS_EXCEPTION_ELIGIBILITY_CODE_COLLISION = 'eligibility_code_collision'
export const ANALYTICS_EXCEPTION_INVALID_PRODUCT_ID = 'invalid_product_id'
export const ANALYTICS_EXCEPTION_KIT_ID_REQUIRED = 'kit_id_required'
export const ANALYTICS_EXCEPTION_KIT_ID_PATTERN_MISMATCH = 'kit_id_pattern_mismatch'
export const ANALYTICS_EXCEPTION_KIT_ID_INCOMPLETE = 'kit_id_incomplete'
export const ANALYTICS_EXCEPTION_CONFIRM_APPOINTMENT_ERROR = 'confirm_appointment_error'
