import { store } from '@/store/index.js'
import { getOmittedFields, productStatusFields } from '@/utils/omitUtils.js'
import { FETCH_PRODUCT_STATUS_LIST } from '@/store/products.js'
import { CONFIG } from '@/constants/config.js'
import { getConfig } from '@/use/useConfig.js'

const getQueryParams = (
  productType,
  page,
  ordering,
  omit,
  pageSize = getConfig(CONFIG.PRODUCT_STATUS_PAGE_SIZE),
  additionalParams = {}
) => {
  return {
    queryParams: {
      page,
      page_size: pageSize,
      product__product_type: productType.toUpperCase(),
      ordering,
      omit,
      ...additionalParams
    }
  }
}

const fetchProductStatusList = async (
  productType,
  page = 1,
  pageSize = getConfig(CONFIG.PRODUCT_STATUS_PAGE_SIZE),
  showCancelledOrders = getConfig(CONFIG.SHOW_CANCELLED_ORDERS_ON_DASHBOARD)
) => {
  const additionalParams = {}
  additionalParams.include_cancelled = showCancelledOrders
  await store.dispatch(
    FETCH_PRODUCT_STATUS_LIST,
    getQueryParams(
      productType,
      page,
      '-created_date',
      getOmittedFields(productStatusFields, store.getters),
      pageSize,
      additionalParams
    )
  )
}

export default {
  getQueryParams,
  fetchProductStatusList
}
