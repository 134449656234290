<i18n src="./lang.json" />

<template>
  <div class="container-fluid wizard-nav">
    <div class="wizard-nav__progress" :style="{ width: `${progress}%` }"></div>

    <div class="row align-items-center wizard-nav__row">
      <div class="col-3">
        <ghost-btn v-if="isBackVisible" class="wizard-nav__back" @click="$emit('back')">
          <icon-base
            fill="none"
            :params="{
              width: '24',
              height: '24',
              viewBox: '0 0 24 24'
            }"
          >
            <ix-icon-back />
          </icon-base>
        </ghost-btn>
      </div>

      <div class="col-6 wizard-nav__logo text-center">
        <img class="wizard-nav__logo-img" :src="logo" :alt="organizationName" />
      </div>

      <div class="col-3 text-right">
        <ghost-btn class="wizard-nav__exit" @click="isPopupOpen = true">
          <span class="d-none d-md-inline">
            {{ $t('mobile_exit_text', [name && name.toLowerCase()]) }}
          </span>
          <icon-base
            :params="{
              width: 24,
              height: 24,
              viewBox: '0 0 24 24',
              name: 'icon.close'
            }"
          >
            <ix-icon-close />
          </icon-base>
        </ghost-btn>
      </div>
    </div>

    <popup-v3
      v-if="isPopupOpen"
      class="p-0"
      :user-action-btns="popupContent.userActionBtns"
      :title="$t('cancel_modal.title')"
      :text1="
        name
          ? $t('cancel_modal.text', [name && name.toLowerCase()])
          : $t('cancel_modal.text_without_wizard_name')
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconClose from '@/components/icons/IxIconClose.vue'
import PopupV3 from '@/components/ui-components/Popup/PopupV3.vue'

import common from '@/classes/common.js'
import GhostBtn from '@/components/ui-components/GhostBtn/GhostBtn.vue'
import IxIconBack from '@/components/icons/IxIconBack.vue'

export default {
  name: 'WizardNavV4',

  components: {
    IxIconBack,
    GhostBtn,
    IconBase,
    IxIconClose,
    PopupV3
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    progress: {
      type: Number,
      default: 0
    },

    isBackVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isPopupOpen: false,
      popupContent: {
        userActionBtns: [
          {
            onClick: false,
            routerLinkTo: 'GoHome',
            title: this.$t('cancel_modal.button_text', [this.name?.toLowerCase()])
          },
          {
            onClick: () => {
              this.isPopupOpen = false
            },
            title: this.$t('cancel_modal.cancel_button_text'),
            isGhost: true
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters(['getThemeValue', 'organizationName', 'getConfig', 'isAuthenticated']),

    logo() {
      return common.cleanCssThemeUrl(this.getThemeValue('--logo-desktop'))
    }
  }
}
</script>

<style lang="scss" scoped>
.wizard-nav {
  position: relative;
  background: $v3-color-white;
  border-bottom: 4px solid $pharma-color-border-primary;

  &__row {
    height: $v3-header-height;
  }

  &__progress {
    position: absolute;
    inset-inline-start: 0;
    inset-block-end: -4px;
    width: 100%;
    height: 4px;
    background-color: $pharma-color-bg-brand;
    transition: width 0.3s;
  }

  &__logo-img {
    min-width: 0; // Not allowed to take up a minimum space, use only as much as available
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: fit-content;
    }
  }

  &__back {
    min-width: 0;
    margin-inline-start: -2px;

    &,
    &:hover,
    &:focus {
      padding-inline: 0;
      color: transparent;
    }
  }

  &__exit {
    justify-content: flex-end;
    margin-inline-end: 2px;
    color: $pharma-color-text-primary;

    &,
    &:hover,
    &:focus {
      padding-inline: 0;
    }
  }
}
</style>
