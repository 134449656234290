import { camelCase } from 'lodash-es'
import { parseTags } from '@/utils/arrayUtils.js'
import { defaultLanguage } from '@/config/translations.js'
import { store } from '@/store/index.js'

export const fetchWorkflowContent = (rootGetters, orgContentId, params = {}) => {
  return rootGetters.cmsApiInstance.get('workflow/', {
    params: {
      org_content_id: orgContentId,
      locale: defaultLanguage,
      live: true,
      ...params
    }
  })
}

export const fetchNorthstarContent = (orgContentId, params = {}) => {
  return store.getters.cmsApiInstance.get('northstar/', {
    params: {
      org_content_id: orgContentId,
      locale: defaultLanguage,
      live: true,
      ...params
    }
  })
}

export const transformWelcomePageContent = (welcomeData) => {
  return {
    hero: {
      ctaPrimary: {
        id: welcomeData.cta_primary?.id || '',
        link: welcomeData.cta_primary?.link || '',
        title: welcomeData.cta_primary?.title || ''
      },
      ctaSecondary: {
        id: welcomeData.cta_secondary?.id || '',
        link: welcomeData.cta_secondary?.link || '',
        title: welcomeData.cta_secondary?.title || ''
      },
      description: welcomeData.description || '',
      heading: welcomeData.heading || '',
      heroImage: {
        image: welcomeData.hero_image?.image || '',
        alt: welcomeData.hero_image?.alt || ''
      }
    },
    whatsAhead: {
      title: welcomeData.whats_ahead?.title || '',
      steps: welcomeData.whats_ahead?.steps || [],
      disclaimer: welcomeData.whats_ahead?.disclaimer || ''
    }
  }
}

export const filterValidWorkflowItems = (items, validWorkflowStates) => {
  return items
    .filter((item) => parseTags(item.meta?.tags).some((tag) => validWorkflowStates.includes(tag)))
    .filter((item) => {
      const pp = item?.meta?.body?.pp
      return pp && (pp.title || pp.headline || pp.text || pp.statuses || pp.instructionsButton)
    })
}

export const reduceWorkflowContent = (items, validWorkflowStates) => {
  return items.reduce((content, item) => {
    const meta = item.meta
    const parsedTags = parseTags(meta.tags)
    const tags = parsedTags
      .filter((tag) => !tag.startsWith('sku:'))
      .sort((a, z) => validWorkflowStates.indexOf(z) - validWorkflowStates.indexOf(a))
    const skus = parsedTags.filter((tag) => tag.startsWith('sku:'))
    const body = meta?.body?.pp
    const state = camelCase(tags[0])
    const key = [state, ...tags.splice(1)].join('|')

    const value = {
      title: body?.title ?? '',
      headline: body?.headline ?? '',
      text: body?.text ?? '',
      statuses: body?.statuses ?? {},
      instructionsButton: body?.instructionsButton || ''
    }

    content[key] = value

    skus.forEach((sku) => {
      const skuKey = [state, sku, ...tags.splice(1)].join('|')
      content[skuKey] = value
    })

    return content
  }, {})
}
