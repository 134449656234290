import { KIT_STATUSES } from '@/constants/kitStatuses.js'
import { WORKFLOW_ICONS } from '@/constants/workflowStates.js'

const flowIds = {
  prescreenNonLdt: [
    KIT_STATUSES.SHIPPED,
    KIT_STATUSES.QUESTIONNAIRE_COMPLETED,
    KIT_STATUSES.REGISTERED,
    KIT_STATUSES.RECEIVED,
    KIT_STATUSES.RESULT_READY
  ],
  prescreenLdt: [
    KIT_STATUSES.PRESCREEN_SUBMITTED,
    KIT_STATUSES.APPROVED,
    KIT_STATUSES.REGISTERED,
    KIT_STATUSES.RECEIVED,
    KIT_STATUSES.IN_ANALYSIS,
    KIT_STATUSES.RESULT_READY
  ],
  ldt: [
    KIT_STATUSES.REGISTERED,
    KIT_STATUSES.ORDER_SUBMITTED,
    KIT_STATUSES.APPROVED,
    KIT_STATUSES.RECEIVED,
    KIT_STATUSES.IN_ANALYSIS,
    KIT_STATUSES.DATA_READY,
    KIT_STATUSES.RESULT_READY
  ],
  nonLdt: [
    KIT_STATUSES.REGISTERED,
    KIT_STATUSES.RECEIVED,
    KIT_STATUSES.IN_ANALYSIS,
    KIT_STATUSES.DATA_READY,
    KIT_STATUSES.RESULT_READY
  ]
}

const statusTrackData = (status) => [
  {
    id: 1,
    status: 'kit_shipped',
    date: status.dates.kitShipped,
    icon: WORKFLOW_ICONS.kitShipped,
    title: 'welcome_back.card.status_tracker.kit_shipped.title',
    text: 'welcome_back.card.status_tracker.kit_shipped.text',
    trackTitle: 'welcome_back.card.status_tracker.kit_shipped.track_title'
  },
  {
    id: 2,
    status: 'ldt_submitted',
    date: status.dates.ldtSubmitted,
    icon: WORKFLOW_ICONS.bloodDrop,
    title: 'welcome_back.card.status_tracker.prescreen.title',
    text: 'welcome_back.card.status_tracker.prescreen.text',
    trackTitle: 'welcome_back.card.status_tracker.prescreen.track_title'
  },
  {
    id: 3,
    status: 'questionnaire_completed',
    date: status.dates.ldtSubmitted,
    icon: WORKFLOW_ICONS.kitActivated,
    title: 'welcome_back.card.status_tracker.questionnaire_completed.title',
    text: 'welcome_back.card.status_tracker.questionnaire_completed.text',
    trackTitle: 'welcome_back.card.status_tracker.questionnaire_completed.track_title'
  },
  {
    id: 4,
    status: 'kit_registered',
    date: status.dates.kitRegistered,
    icon: WORKFLOW_ICONS.kitActivated,
    title: 'welcome_back.card.status_tracker.kit_registered.title',
    text: 'welcome_back.card.status_tracker.kit_registered.text',
    trackTitle: 'welcome_back.card.status_tracker.kit_registered.track_title'
  },
  {
    id: 5,
    status: 'ldt_submitted',
    date: status.dates.ldtSubmitted,
    icon: WORKFLOW_ICONS.inReview,
    title: 'welcome_back.card.status_tracker.order_review.title',
    text: 'welcome_back.card.status_tracker.order_review.text',
    trackTitle: 'welcome_back.card.status_tracker.order_review.track_title'
  },
  {
    id: 6,
    status: 'ldt_approved',
    date: status.dates.ldtApproved,
    icon: WORKFLOW_ICONS.bloodDrop,
    title: 'welcome_back.card.status_tracker.clinician_approval.title',
    text: 'welcome_back.card.status_tracker.clinician_approval.text',
    trackTitle: 'welcome_back.card.status_tracker.clinician_approval.track_title'
  },
  {
    id: 7,
    status: 'in_lab',
    date: status.dates.kitReceived,
    icon: WORKFLOW_ICONS.inLab,
    title: 'welcome_back.card.status_tracker.sample_received.title',
    text: 'welcome_back.card.status_tracker.sample_received.text',
    trackTitle: 'welcome_back.card.status_tracker.sample_received.track_title'
  },
  {
    id: 8,
    status: 'in_analysis',
    date: status.dates.inAnalysis,
    icon: WORKFLOW_ICONS.inLab,
    title: 'welcome_back.card.status_tracker.dna_process.title',
    text: 'welcome_back.card.status_tracker.dna_process.text',
    trackTitle: 'welcome_back.card.status_tracker.dna_process.track_title'
  },
  {
    id: 9,
    status: 'data_ready',
    date: status.dates.dataReady,
    icon: WORKFLOW_ICONS.inLab,
    title: 'welcome_back.card.status_tracker.dna_complete.title',
    text: 'welcome_back.card.status_tracker.dna_complete.text',
    trackTitle: 'welcome_back.card.status_tracker.dna_complete.track_title'
  },
  {
    id: 10,
    status: 'result_approved',
    pillType: 'success',
    date: status.dates.resultApproved,
    icon: WORKFLOW_ICONS.resultReady,
    title: 'welcome_back.card.status_tracker.results_approved.title',
    text: 'welcome_back.card.status_tracker.results_approved.text',
    trackTitle: 'welcome_back.card.status_tracker.results_approved.track_title',
    btnTitle: 'btn.view_results',
    cssClassForBtnWrap: 'card__btn-wrap--flex-center'
  }
]

const statusErrorDetails = (status, organization) => ({
  isDeactivated: {
    priority: 1,
    pillType: 'cancelled',
    icon: WORKFLOW_ICONS.cancelled,
    date: status.dates.deactivated,
    message: 'welcome_back.card.status_error.deactivated.message',
    title: 'welcome_back.card.status_error.deactivated.title',
    text: organization?.help_center_url
      ? 'welcome_back.card.status_error.deactivated.text_with_help_link'
      : 'welcome_back.card.status_error.deactivated.text'
  },
  hasLdtRejected: {
    priority: 2,
    pillType: 'error',
    icon: WORKFLOW_ICONS.error,
    date: status.dates.ldtRejected,
    message: 'welcome_back.card.status_error.ldt_rejected.message',
    title: 'welcome_back.card.status_error.ldt_rejected.title',
    text: organization?.help_center_url
      ? 'welcome_back.card.status_error.ldt_rejected.text_with_help_link'
      : 'welcome_back.card.status_error.ldt_rejected.text'
  },
  hasResultRejected: {
    priority: 3,
    pillType: 'error',
    icon: WORKFLOW_ICONS.error,
    date: status.dates.resultRejected,
    message: 'welcome_back.card.status_error.result_rejected.message',
    title: 'welcome_back.card.status_error.result_rejected.title',
    text: organization?.help_center_url
      ? 'welcome_back.card.status_error.result_rejected.text_with_help_link'
      : 'welcome_back.card.status_error.result_rejected.text'
  },
  hasPurchaseCancelled: {
    priority: 4,
    pillType: 'cancelled',
    icon: WORKFLOW_ICONS.cancelled,
    date: status.dates.purchaseCancelled,
    message: 'welcome_back.card.status_error.purchase_cancelled.message',
    title: 'welcome_back.card.status_error.purchase_cancelled.title',
    text: organization?.help_center_url
      ? 'welcome_back.card.status_error.purchase_cancelled.text_with_help_link'
      : 'welcome_back.card.status_error.purchase_cancelled.text'
  },
  hasKitError: {
    priority: 5,
    pillType: 'error',
    icon: WORKFLOW_ICONS.error,
    date: status.dates.kitError,
    message: 'welcome_back.card.status_error.kit_error.message',
    title: 'welcome_back.card.status_error.kit_error.title',
    text: organization?.help_center_url
      ? 'welcome_back.card.status_error.kit_error.text_with_help_link'
      : 'welcome_back.card.status_error.kit_error.text'
  },
  isPaymentPending: {
    priority: 6,
    pillType: 'error',
    icon: WORKFLOW_ICONS.dollar,
    message: 'welcome_back.card.status_error.payment_pending.message',
    title: 'welcome_back.card.status_error.payment_pending.title',
    text: 'welcome_back.card.status_error.payment_pending.text'
  },
  isInsurancePending: {
    priority: 7,
    pillType: 'error',
    icon: WORKFLOW_ICONS.dollar,
    message: 'welcome_back.card.status_error.insurance_pending.message',
    title: 'welcome_back.card.status_error.insurance_pending.title',
    text: 'welcome_back.card.status_error.insurance_pending.text'
  },
  isPaymentAndInsurancePending: {
    priority: 8,
    pillType: 'error',
    icon: WORKFLOW_ICONS.dollar,
    message: 'welcome_back.card.status_error.payment_and_insurance_pending.message',
    title: 'welcome_back.card.status_error.payment_and_insurance_pending.title',
    text: 'welcome_back.card.status_error.payment_and_insurance_pending.text'
  },
  hasKitReshipped: {
    priority: 9,
    icon: WORKFLOW_ICONS.kitShipped,
    date: status.dates.kitReshipped,
    message: 'welcome_back.card.status_error.kit_reshipped.message',
    title: 'welcome_back.card.status_error.kit_reshipped.title',
    text: 'welcome_back.card.status_error.kit_reshipped.text'
  },
  kitShipmentPending: {
    priority: 10,
    icon: WORKFLOW_ICONS.kitShipped,
    message: 'welcome_back.card.status_error.kit_shipment_pending.message',
    title: 'welcome_back.card.status_error.kit_shipment_pending.title',
    text: 'welcome_back.card.status_error.kit_shipment_pending.text'
  },
  kitRegisteredWithoutConsent: {
    priority: 11,
    icon: WORKFLOW_ICONS.bloodDrop,
    title: 'welcome_back.card.status_error.kit_registered_without_consent.title',
    text: 'welcome_back.card.status_error.kit_registered_without_consent.text'
  }
})

const checkInsuranceError = (flowData, errorKey) => {
  if (errorKey && errorKey === 'isPaymentPending') {
    const { has_dtc, has_insurance } = flowData
    errorKey = has_dtc && has_insurance ? 'isPaymentAndInsurancePending' : errorKey
    errorKey = !has_dtc && has_insurance ? 'isInsurancePending' : errorKey
  }
  return errorKey
}

const getDetails = (product, status, organization) => {
  const { flowData } = product

  let ids = []
  if (flowData?.has_prescreen && !flowData?.has_ldt) {
    ids = flowIds.prescreenNonLdt
  } else if (flowData?.has_prescreen) {
    ids = flowIds.prescreenLdt
  } else if (flowData?.has_ldt) {
    ids = flowIds.ldt
  } else {
    ids = flowIds.nonLdt
  }

  const productName = product.name
  const statuses = ids.map((id) =>
    statusTrackData(status, product).find((status) => status.id === id)
  )

  statuses.sort(
    (a, b) => (a.date === null) - (b.date === null) || +(a.date > b.date) || -(a.date < b.date)
  )

  // The statuses are displayed based on their dates. In prescreenNonLdt flow we have kit_registered and questionnaire_completed statuses.
  // If the user do Kit activation flow, the kit_registered happens first, then the questionnaire_completed. For the proper display,
  // we need to change their order in the statuses array.
  let questionnaireCompletedIndex = null
  let kitRegisteredIndex = null
  statuses.forEach((item, index) => {
    if (item.status === 'questionnaire_completed' && item.date) questionnaireCompletedIndex = index
    if (item.status === 'kit_registered' && item.date) kitRegisteredIndex = index
  })
  if (
    questionnaireCompletedIndex !== null &&
    kitRegisteredIndex !== null &&
    kitRegisteredIndex < questionnaireCompletedIndex
  ) {
    ;[statuses[kitRegisteredIndex], statuses[questionnaireCompletedIndex]] = [
      statuses[questionnaireCompletedIndex],
      statuses[kitRegisteredIndex]
    ]
  }

  const reverseActiveIndex = statuses
    .slice()
    .reverse()
    .findIndex((step) => step.date)
  const count = statuses.length - 1
  const activeIndex = reverseActiveIndex >= 0 ? count - reverseActiveIndex : reverseActiveIndex

  const errorCases = status.errorCases
  const errorDetails = statusErrorDetails(status, organization)
  let errors = Object.keys(errorCases)
    .filter((key) => errorCases[key])
    .map((key) => checkInsuranceError(flowData, key))
    .map((key) => errorDetails[key])
  errors = errors.sort((a, b) => a.priority - b.priority)
  const error = errors.length > 0 ? errors[0] : null

  const getActiveStatusDetails = () => {
    if (error) {
      if (status.kit_rejected_reason) {
        return { text: status.kit_rejected_reason }
      }
      return error
    } else if (status.hasResultReady && flowData?.has_ldt && !status.hasLdtApproved) {
      return {
        text: 'welcome_back.card.status_tracker.results_ready_and_non_ldt_approved.text'
      }
    } else {
      return [...statuses].reverse().find((status) => status.date !== null) ?? statuses[activeIndex]
    }
  }

  const activeStatusDetails = getActiveStatusDetails()
  const errorMessage = error ? error.message : null

  const isDisabled = Object.keys(errors).some((key) => errors[key])

  return {
    productName,
    statuses,
    errorMessage,
    activeIndex,
    activeStatusDetails,
    isDisabled
  }
}

export default {
  getDetails
}
