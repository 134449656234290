// This is a temporary file to fetch data for Pharma Dashboard development.
// After we hook up the code for the new BE, this file can be deleted.

import Vue from 'vue'
import { i18n } from '@/plugins/i18n.js'

import { store } from '@/store/index.js'
import statusService from '@/components/views/Dashboard/Partials/services/_statusServiceV3.js'
import common from '@/classes/common.js'
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { getProductDetails } from '@/utils/productUtils.js'
import { getWorkflowState } from '@/utils/productStatusUtils.js'
import kitStatusService from '@/components/views/Dashboard/Partials/services/_kitStatusServiceV3.js'
import { CONFIG } from '@/constants/config.js'

const productType = 'test'

export const fetchLatestProductStatusList = async () => {
  try {
    await statusService.fetchProductStatusList(productType, 1, 10)
  } catch (e) {
    Vue.toasted.show(i18n.t('error.default_msg'))
  }
}

// TODO: Need to clean this up
const getMyProductStatuses = async (productList, productStatusList = []) => {
  if (!store.getters.organization) {
    return []
  }

  const productStatuses = []

  for (const productStatus of productStatusList) {
    let product = productList.find((pr) => pr.id === productStatus.product_id)
    if (!product) {
      try {
        const res = await IXLayerAPI.getProductById(productStatus.product_id)
        product = res.data
      } catch {
        Vue.toasted.show(i18n.t('error.default_msg'))
      }
    }

    const productDetails = getProductDetails(product, store.getters.organization)
    const isInsuranceRequired =
      productDetails.flowData?.has_insurance && !productDetails.flowData?.insurance_not_required

    const statusParams = common.getProductStatusDetails(productStatus, store)

    const status = {
      productStatusId: productStatus.id,
      orderId: productStatus.order_id,
      customData: statusParams.customData,
      fulfillments: productStatus.fulfillments,
      orgId: store.getters.organization?.id,
      provider: statusParams?.customData?.test_approval,
      instance: productStatus.label,
      hasLdtApproved: statusParams.hasLdtApproved(),
      transitionHistory: productStatus.transition_history,
      possibleStates: productStatus.possible_states,
      isRecoverable: productStatus.is_recoverable,
      isRequisitionAvailable: productStatus.is_requisition_available,
      hasProductStatus: statusParams.hasProductStatus(),
      hasKitShipped: statusParams.hasKitShipped(),
      hasRegisteredKit: statusParams.hasRegisteredKit(),
      hasAnsweredQuestionnaire: statusParams.hasAnsweredQuestionnaire(),
      hasConsentedToTest: statusParams.hasConsentedToTest(),
      hasResultReady: statusParams.hasResultReady(),
      hasResultApproved: statusParams.hasResultApproved(),
      hasMobilePhlebotomyAppointment: statusParams.hasMobilePhlebotomyAppointment(),
      hasServiceCenterAppointment: statusParams.hasServiceCenterAppointment(),
      hasViewedResult: statusParams.hasViewedResult(),
      dates: statusParams.dates,
      errorCases: {
        hasKitError: statusParams.errorCases.hasKitError(),
        hasKitReshipped: statusParams.errorCases.hasKitReshipped(),
        hasLdtRejected: statusParams.errorCases.hasLdtRejected(),
        hasPurchaseCancelled: statusParams.errorCases.hasPurchaseCancelled(),
        hasResultRejected: statusParams.errorCases.hasResultRejected(),
        isDeactivated: statusParams.errorCases.isDeactivated(),
        isPaymentPending:
          productDetails?.flowData?.has_dtc || isInsuranceRequired
            ? !(statusParams.hasPurchased() || statusParams.hasInsuranceSubmitted())
            : false,
        kitShipmentPending: productDetails?.flowData?.ship_to_patient
          ? !statusParams.hasKitShipped() && !statusParams.hasRegisteredKit()
          : false,
        kitRegisteredWithoutConsent:
          statusParams.hasRegisteredKit() && !statusParams.hasConsentedToTest()
      },
      state: getWorkflowState(
        productStatus,
        store.getters.getConfig(CONFIG.USE_STATE_WORKFLOW_PROPERTY)
      )
    }

    const statusDetails = {
      ...kitStatusService.setParams(
        productDetails,
        status,
        store.getters.userFullName,
        store.getters.organization
      ),
      product: productDetails,
      status
    }

    productStatuses.push(statusDetails)
  }

  return productStatuses
}

export const getMyOrderDetails = async () => {
  await fetchLatestProductStatusList()
  return await getMyProductStatuses(store.getters.productList, store.getters.productStatusList)
}
