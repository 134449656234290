import common from '@/classes/common.js'
import statusTrackService from '@/components/views/Dashboard/Partials/services/_kitStatusTrackServiceV3.js'
import router from '@/router/index.js'
import { store } from '@/store/index.js'
import { CONFIG } from '@/constants/config.js'
import { getConfig } from '@/use/useConfig.js'

import IxIconLocationMark from '@/components/icons/IxIconLocationMark.vue'
import { i18n } from '@/plugins/i18n.js'
import { goToPurchaseProduct } from '@/utils/productUtils.js'
import { trackButtonClick } from '@/utils/analytics/customEvents.js'
import { ANALYTICS_DIMENSION_PRODUCT_STATUS_ID } from '@/constants/analytics.js'

let currentActiveBtn = null

const locationMark = {
  component: IxIconLocationMark,
  params: {
    width: '12',
    height: '16',
    viewBox: '0 0 12 16',
    name: 'icon.locations'
  }
}

const viewResult = (product, status, e) => {
  setActiveBtn(status.productStatusId)
  e.preventDefault()
  e.stopPropagation()
  common.viewResult(product, status, router)
}

const purchase = (product, status) => {
  setActiveBtn(status.productStatusId)
  goToPurchaseProduct(product, status.productStatusId, status.orderId, status.orgId, router)
}

const downloadRequisitionForm = async (productStatusId, ev, showError) => {
  setActiveBtn(productStatusId)
  ev.preventDefault()
  ev.stopPropagation()
  return common.downloadRequisitionForm(productStatusId).catch(() => {
    showError('welcome_back.card.pdf_download_error_msg')
  })
}

const goToLocation = (product) => {
  router.push({ name: 'Location', params: { product } }).catch(() => {})
}

const setActiveBtn = (id) => (currentActiveBtn = id)

const getActiveBtn = (buttonId) => `${currentActiveBtn}-${buttonId}`

const checkFlowType = (flowType, product) => Boolean(product.flowData && product.flowData[flowType])

const atHomeTest = (product) => checkFlowType('has_at_home_test', product)
const hideKitActivationButton = (product) => checkFlowType('hide_kit_activation_button', product)
const hasGmap = (product) => checkFlowType('ppp_gmp', product)
const hasPreResultConsent = (product) => checkFlowType('has_pre_result_consent', product)
const hasLdtApproved = (product, status) => {
  return checkFlowType('has_ldt', product) ? status.hasLdtApproved : true
}
const hasResultReady = (status) => status.hasResultReady
const hasResultApproved = (status) => status.hasResultApproved

const getResultReadyStatusSpecificsData = (product, status) => {
  const displayDate = (date) =>
    common.displayDate(common.getDateObj(date, true), getConfig(CONFIG.DATE_TIME_FORMAT)?.short)

  const data = [{ title: 'card.created_date', text: displayDate(status.dates.created) }]
  if (checkFlowType('has_ldt', product)) {
    // TODO: need to add the data to the BE first
    // data.push({ title: 'welcome_back.card.status_details.referring_clinician', text: '' })
  }
  return data
}

const atHomeNoQuestionnaire = (product) => checkFlowType('at_home_no_questionnaire', product)

// In all orders could show different content based on status of the product. Here we are getting
// details for card buttons and status specific descriptions when the status is ready
const getParams = (product, status) => {
  const productType = product.type
  const productId = product.id
  const isLdtProduct = product.flowData?.has_ldt
  const productStatusId = status.productStatusId

  const isResultApproved = store.getters.useWorkflowStatusesEnabled
    ? hasResultApproved(status)
    : hasResultApproved(status) && hasResultReady(status)

  if (
    !status.isRecoverable &&
    (status.errorCases.hasLdtRejected ||
      status.errorCases.hasResultRejected ||
      status.errorCases.hasPurchaseCancelled ||
      status.errorCases.hasKitError)
  ) {
    return {
      btns: getConfig(CONFIG.HELP_CENTER_URL)
        ? [
            {
              title: 'btn.contact_support',
              externalLink: getConfig(CONFIG.HELP_CENTER_URL)
            }
          ]
        : getConfig(CONFIG.SUPPORT_EMAIL)
          ? [
              {
                title: 'btn.contact_support',
                mailTo: getConfig(CONFIG.SUPPORT_EMAIL)
              }
            ]
          : null,
      statusSpecifics: {
        description2: getConfig(CONFIG.SUPPORT_PHONE)
      }
    }
  } else if (status.errorCases.isPaymentPending) {
    // The product is DTC and hasn't been purchased yet
    return {
      btns: [{ title: 'btn.go_to_checkout', onClick: purchase }]
    }
  } else if (
    hasGmap(product) &&
    ((!atHomeTest(product) && !hasLdtApproved(product, status) && !status.hasRegisteredKit) ||
      status.errorCases.hasKitReshipped)
  ) {
    // The product is In Clinic test (not at home) and hasn't been registered yet
    return {
      btns: [
        {
          title: 'btn.collections_facilities',
          onClick: goToLocation,
          icon: locationMark
        }
      ]
    }
  } else if (
    (!isResultApproved &&
      !status.hasRegisteredKit &&
      (hasLdtApproved(product, status) ||
        (atHomeTest(product) && !status.errorCases.kitShipmentPending))) ||
    status.errorCases.hasKitReshipped
  ) {
    // The product hasn't been registered yet
    return {
      btns:
        atHomeTest(product) && !hideKitActivationButton(product)
          ? [
              {
                title: 'btn.activate_kit',
                linkToParameters: {
                  name: 'KitActivate',
                  params: { productType, productId, productStatusId }
                }
              }
            ]
          : hasGmap(product)
            ? [
                {
                  title: 'btn.collections_facilities',
                  onClick: goToLocation,
                  icon: locationMark
                }
              ]
            : null,
      statusSpecifics: {
        link: atHomeTest(product)
          ? null
          : status.isRequisitionAvailable
            ? {
                title: i18n.t('requisition_form'),
                onClick: async (ev, errorCallback, successFallback) => {
                  trackButtonClick('Requisition form', {
                    [ANALYTICS_DIMENSION_PRODUCT_STATUS_ID]: productStatusId
                  })
                  await downloadRequisitionForm(productStatusId, ev, errorCallback)
                  if (typeof successFallback === 'function') {
                    successFallback()
                  }
                }
              }
            : null
      }
    }
  } else if (
    atHomeTest(product) &&
    status.hasRegisteredKit &&
    (!status.hasAnsweredQuestionnaire || !status.hasConsentedToTest) &&
    !atHomeNoQuestionnaire(product)
  ) {
    // The kit has been purchased and registered but the registration flow is not ready yet
    let routerName = ''
    if (!status.hasAnsweredQuestionnaire) {
      routerName = 'HealthHistory'
    } else if (!status.hasConsentedToTest) {
      routerName = 'GiveConsent'
    }

    return {
      btns: [
        {
          title: 'btn.continue_activate_flow',
          linkToParameters: {
            name: routerName,
            params: {
              productId,
              productStatusId,
              label: status.instance,
              collectionLocation: 'At home', // Updated in OrderCollapseDetailButtonRow component
              kitId: '' // Updated in OrderCollapseDetailButtonRow component
            }
          }
        }
      ]
    }
  } else if (
    !isResultApproved &&
    atHomeTest(product) &&
    ((hasLdtApproved(product, status) && status.hasRegisteredKit) ||
      status.errorCases.hasKitReshipped)
  ) {
    // The product has at home test and the kit has been registered
    return {
      statusSpecifics: {
        description: 'welcome_back.card.status_details.kit_registered'
      }
    }
  } else if (
    isResultApproved &&
    hasLdtApproved(product, status) &&
    hasPreResultConsent(product) &&
    !status.hasViewedResult
  ) {
    // The kit result is ready but the user has not consented to the product yet
    return {
      btns: [
        {
          title: 'btn.view_results',
          linkToParameters: {
            name: 'PreResultConsent',
            params: { productId, productStatusId }
          }
        }
      ],
      statusSpecifics: {
        description: product.description,
        data: getResultReadyStatusSpecificsData(product, status)
      }
    }
  } else if (
    isResultApproved &&
    hasLdtApproved(product, status) &&
    (!isLdtProduct ||
      !hasPreResultConsent(product) ||
      (isLdtProduct && hasPreResultConsent(product) && status.hasViewedResult))
  ) {
    // The kit result is ready and the user has consented to the product
    return {
      btns: [
        {
          title: 'btn.view_results',
          onClick: viewResult
        }
      ],
      statusSpecifics: {
        description: product.description,
        data: getResultReadyStatusSpecificsData(product, status)
      }
    }
  }
}

const setParams = (product, status, userFullName, organization) => {
  const params = getParams(product, status)
  return {
    title: product.name,
    instance: userFullName,
    productImage: product.image,
    productStatusId: status.productStatusId,
    btns: params
      ? params.btns?.map((btn) => ({
          title: btn.title || '',
          class: 'btn-block btn-primary font-bc',
          success: btn.success,
          onClick: btn.onClick ? (e) => btn.onClick(product, status, e) : null,
          isDisabled: btn.isDisabled,
          isLoading: false,
          externalLink: btn.externalLink ? btn.externalLink : null,
          mailTo: btn.mailTo ? btn.mailTo : null,
          linkToParameters: btn.linkToParameters ? btn.linkToParameters : null,
          icon: btn.icon ? btn.icon : null
        }))
      : null,
    statusSpecifics: params?.statusSpecifics ? params.statusSpecifics : {},
    statusTrackData: statusTrackService.getDetails(product, status, organization)
  }
}

export default {
  setParams,
  getActiveBtn
}
