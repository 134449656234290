<template>
  <header class="header px-0">
    <wizard-nav-v4 :progress="progress" :is-back-visible="isBackVisible" @back="handleBackClick" />
  </header>
</template>

<script>
import WizardNavV4 from '@/components/ui-components/WizardNav/WizardNavV4.vue'

export default {
  name: 'SelectTestHeader',

  components: { WizardNavV4 },

  props: {
    progress: {
      type: Number,
      default: 0
    },

    isBackVisible: {
      type: Boolean,
      default: false
    },

    handleBackClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
