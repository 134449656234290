<template>
  <div id="app" class="app">
    <site-unavailable-error v-if="isInvalidReferrer" />

    <template v-else-if="canShowAppContent">
      <transition v-if="popupComponent" name="fade-in">
        <component :is="popupComponent" />
      </transition>

      <transition name="page-anim" mode="out-in">
        <router-view :key="$route.fullPath" class="app__header-and-page" />
      </transition>

      <app-footer-v3
        v-if="!$route.meta.isFooterHidden && !featureFlags.pharmaPatientPortal"
        class="app__footer"
        :is-hidden="$route.meta.isLinksHidden"
        :is-disabled="$route.meta.isLinksDisabled"
      />

      <portal-target name="popover-target" class="app__popover" multiple />
    </template>
  </div>
</template>

<script>
import AppFooterV3 from '@/components/views/Partials/FooterV3/FooterV3.vue'
import AutoLogoutPopup from '@/components/views/Partials/AutoLogoutPopup/AutoLogoutPopup.vue'
import ErrorPopup from '@/components/views/Partials/ErrorPopup/ErrorPopup.vue'
import SiteUnavailableError from '@/components/views/SiteUnavailableError/SiteUnavailableError.vue'
import { Trans } from '@/plugins/Translation.js'
import '@/assets/styles/main.scss'
import { PortalTarget } from 'portal-vue'
import { mapGetters } from 'vuex'
import { CHECK_CONNECTION } from '@/store/notifications.js'

const CONNECTION_POLL_PERIOD_SEC = 10

export default {
  name: 'App',

  components: {
    AppFooterV3,
    AutoLogoutPopup,
    ErrorPopup,
    SiteUnavailableError,
    PortalTarget
  },

  computed: {
    ...mapGetters([
      'productList',
      'productListError',
      'idleTimeout',
      'hasConnectionError',
      'hasInvalidToken',
      'authStatus',
      'isInvalidReferrer',
      'isThemeLoaded',
      'themeLoadingError',
      'featureFlags'
    ]),

    isSiteUnavailable() {
      return Boolean(this.hasConnectionError || this.themeLoadingError)
    },

    popupComponent() {
      if (this.isSiteUnavailable) {
        return ErrorPopup
      } else if (this.idleTimeout) {
        return AutoLogoutPopup
      }
      return null
    },

    canShowAppContent() {
      if (this.isSiteUnavailable || this.hasInvalidToken) return true
      return (
        this.isThemeLoaded &&
        !this.isInvalidReferrer &&
        Boolean(this.productList || this.productListError)
      )
    }
  },

  watch: {
    authStatus(status) {
      if (status === 'tokenInvalid') {
        return this.$router.push(Trans.i18nRoute({ name: 'Login' })).catch(() => {})
      }
    },

    isSiteUnavailable(wasAvailable, isAvailable) {
      if (!wasAvailable && isAvailable) {
        window.location.reload()
      }
    }
  },

  created() {
    const checkConnectionId = setInterval(() => {
      if (!this.isSiteUnavailable) {
        clearInterval(checkConnectionId)
        return
      }

      this.$store.dispatch(CHECK_CONNECTION)
    }, CONNECTION_POLL_PERIOD_SEC * 1000)
  }
}
</script>

<style lang="scss" scoped>
// primary var is from the remote theme, while fallback option is for PP v2 - from app-config
$bg-image-desktop: var(--backgroundImage-desktop, var(--app-bg-img, none));
$bg-image-mobile: var(--backgroundImage-mobile, var(--app-bg-img, none));

.app {
  background-color: $app-bg-color;
  background-image: $bg-image-desktop;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include media-breakpoint-down(sm) {
    background-image: $bg-image-mobile;
  }

  &__header-and-page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }

  &__footer {
    background-color: $footer-bg-color;
  }

  &__popover {
    z-index: 1000;
  }
}
</style>
