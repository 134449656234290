import { trackEvent } from '@/utils/analytics/events.js'
import { ANALYTICS_DIMENSION_BUTTON_LABEL } from '@/constants/analytics.js'

export const trackLinkClick = (url, parameters = {}) => {
  trackEvent('link_click', {
    link_url: url,
    ...parameters
  })
}

export const trackButtonClick = (label, parameters = {}) => {
  trackEvent('btn_click', {
    [ANALYTICS_DIMENSION_BUTTON_LABEL]: label,
    ...parameters
  })
}
