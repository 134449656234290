import { i18n } from '@/plugins/i18n.js'
import { store } from '@/store/index.js'
// Signed in pages
const SignupProfile = () => import('@/components/views/SignupProfile/SignupProfileV3.vue')
const Settings = () => import('@/components/views/Settings/Settings.vue')
const ProfileV3 = () => import('@/components/views/Settings/Profile.vue')
const HealthCareProviders = () => import('@/components/views/Settings/HealthCareProviders.vue')
const HealthCareProviderCreate = () =>
  import('@/components/views/Settings/HealthCareProviderCreate.vue')
const HealthCareProviderView = () =>
  import('@/components/views/Settings/HealthCareProviderView.vue')
const Insurance = () =>
  import('@/components/views/Settings/Insurance/SettingsInsuranceCollapseList.vue')
const Guardian = () => import('@/components/views/Settings/Guardian.vue')
const Notifications = () => import('@/components/views/Settings/Notifications.vue')
const Security = () => import('@/components/views/Settings/Security.vue')
const StaticResults = () => import('@/components/views/StaticResults/StaticResultsV3.vue')
const Report = () => import('@/components/views/Report/views/Report.vue')
const Orders = () => import('@/components/views/Orders/OrdersV3.vue')
const Location = () => import('@/components/views/Location/Location.vue')

export default [
  {
    path: 'signup-profile',
    name: 'SignupProfile',
    component: SignupProfile,
    meta: {
      title: i18n.t('headful_title.signup_profile'),
      requiresAuth: true,
      isLinksHidden: true
    }
  },
  {
    path: 'settings',
    redirect: 'settings/profile',
    name: 'Settings',
    component: Settings,
    meta: {
      title: i18n.t('headful_title.settings_profile'),
      requiresAuth: true
    },
    children: [
      {
        path: 'profile',
        name: 'ProfileV3',
        meta: {
          title: i18n.t('headful_title.settings_profile'),
          requiresAuth: true
        },
        component: ProfileV3
      },
      {
        path: 'hcp',
        name: 'HCP',
        meta: {
          title: i18n.t('headful_title.settings_hcp'),
          requiresAuth: true
        },
        component: HealthCareProviders
      },
      {
        path: 'hcp/create',
        name: 'HCPCreate',
        meta: {
          title: i18n.t('headful_title.settings_hcp'),
          requiresAuth: true
        },
        component: HealthCareProviderCreate
      },
      {
        path: 'hcp/:providerId',
        name: 'HCPView',
        meta: {
          title: i18n.t('headful_title.settings_hcp'),
          requiresAuth: true
        },
        component: HealthCareProviderView,
        props: true
      },
      {
        path: 'insurance',
        name: 'Insurance',
        meta: {
          title: i18n.t('headful_title.settings_insurance'),
          requiresAuth: true
        },
        component: Insurance
      },
      {
        path: 'guardian',
        name: 'Guardian',
        meta: {
          title: i18n.t('headful_title.settings_guardian'),
          requiresAuth: true
        },
        component: Guardian
      },
      {
        path: 'notifications',
        name: 'Notifications',
        meta: {
          title: i18n.t('headful_title.settings_notifications'),
          requiresAuth: true
        },
        component: Notifications
      },
      {
        path: 'security',
        name: 'Security',
        meta: {
          title: i18n.t('headful_title.settings_security'),
          requiresAuth: true
        },
        component: Security
      }
    ]
  },
  {
    path: 'results/:productId/:productStatusId',
    name: 'Results',
    component: () => {
      return store.getters.featureFlags.enableV4TestResults
        ? import('@/components/views/Results/views/ResultsV4.vue')
        : import('@/components/views/Results/views/Results.vue')
    },
    props: true,
    meta: {
      title: i18n.t('headful_title.results'),
      requiresAuth: true,
      hasManualPageViewTracking: true
    }
  },
  {
    path: 'static-results/:productId/:productStatusId',
    name: 'StaticResults',
    component: StaticResults,
    props: true,
    meta: {
      title: i18n.t('headful_title.results'),
      requiresAuth: true
    }
  },
  {
    path: 'report/:productId/:productStatusId',
    name: 'Report',
    component: Report,
    props: true,
    meta: {
      title: i18n.t('headful_title.report'),
      requiresAuth: true,
      isFooterHidden: true,
      isHeaderHidden: true
    }
  },
  {
    path: 'purchase-history',
    name: 'Orders',
    component: Orders,
    meta: {
      title: i18n.t('headful_title.purchase_history'),
      requiresAuth: true
    }
  },
  {
    path: 'location',
    name: 'Location',
    component: Location,
    props: true,
    meta: {
      title: i18n.t('headful_title.location'),
      requiresAuth: true
    }
  }
]
